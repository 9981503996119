<template>
  <div>
    <div class="row">
      <!-- 1st place -->
      <div class="col-xl-4">
        <div class="card card-custom card-stretch gutter-b">
          <div class="card-body p-0">
            <div class="card-rounded p-8 bgi-no-repeat" style="background-color: #FFFFFF; background-position: left bottom; background-size: auto 100%;">
              <b-row align-h="between">
                <b-col cols="auto" class="h3">อันดับ 1</b-col>
                <b-col cols="auto" class="h3">{{ rank1.name }}</b-col>
              </b-row>
              <div class="h3 font-weight-bolder display-4 text-right mt-8">{{ rank1.pts }}</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 2nd place -->
      <div class="col-xl-4">
        <div class="card card-custom card-stretch gutter-b">
          <div class="card-body p-0">
            <div class="card-rounded p-8 bgi-no-repeat" style="background-color: #FFFFFF; background-position: left bottom; background-size: auto 100%;">
              <b-row align-h="between">
                <b-col cols="auto" class="h3">อันดับ 2</b-col>
                <b-col cols="auto" class="h3">{{ rank2.name }}</b-col>
              </b-row>
              <div class="h3 font-weight-bolder display-4 text-right mt-8">{{ rank2.pts }}</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 3rd place -->
      <div class="col-xl-4">
        <div class="card card-custom card-stretch gutter-b">
          <div class="card-body p-0">
            <div class="card-rounded p-8 bgi-no-repeat" style="background-color: #FFFFFF; background-position: left bottom; background-size: auto 100%;">
              <b-row align-h="between">
                <b-col cols="auto" class="h3">อันดับ 3</b-col>
                <b-col cols="auto" class="h3">{{ rank3.name }}</b-col>
              </b-row>
              <div class="h3 font-weight-bolder display-4 text-right mt-8">{{ rank3.pts }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Leaderboard -->
    <div class="card card-custom gutter-b card-stretch">
      <div class="card-header border-0 py-5">
        <h3 class="card-title align-items-start flex-column">
          <span class="card-label font-weight-bolder">Leaderboard</span>
          <span class="text-muted mt-3 font-weight-bold font-size-sm">Hall of fame from {{ members }} active members</span>
        </h3>
        <div class="card-toolbar">
        </div>
      </div>
      <div class="card-body pt-2">
        <b-row v-for="r in users" v-bind:key="r.rank" align-v="center" class="mb-4">
          <b-col cols="auto">
            <div class="symbol symbol-40 symbol-light">
              <div class="symbol-label">
                123
              </div>
            </div>
          </b-col>
          <b-col class="font-weight-bolder">
            {{ r.name }}
          </b-col>
          <b-col sm="*" :xs="10" class="font-weight-bolder">
            {{ r.pts }} คะแนน
          </b-col>
          <b-col :sm="2" xs="*" class="text-right">
            {{ df(r) }}
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tops: [],
      users: [],
      members: 0
    }
  },
  methods: {
    recvData() {
      this.axios.get(`/tournaments/${this.tour}/leaderboard`).then(
        ({ data }) => {
          this.tops = data.tops || []
          this.users = data.users || []
          this.members = data.members || 0
        }
      )
    },
    df (p) {
      return p.diff > 0 ? `+${p.diff}%` : `-${p.diff}%`
    }
  },
  computed: {
    rank1() {
      return this.tops[0] || {}
    },
    rank2() {
      return this.tops[1] || {}
    },
    rank3() {
      return this.tops[2] || {}
    },
    tour() {
      return this.$route.params.tour
    },
    game() {
      return this.$route.params.game
    }
  },
  mounted() {
    this.recvData()
  }
}
</script>

<style>

</style>